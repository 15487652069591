.signup-button {
    all: unset;
    display: flex;
    flex-direction: column;
    position: relative;
    appearance: none;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    border-radius: 4px;
    text-align: center;
    width: -webkit-fill-available;
    align-self: center;
    padding: 20px 30px;
    cursor: pointer;
}
.login-button {
    all: unset;
    display: flex;
    flex-direction: column;
    position: relative;
    appearance: none;
    background-color: #F3F3F3;
    color: black;
    border-radius: 4px;
    text-align: center;
    width: -webkit-fill-available;
    align-self: center;
    padding: 20px 30px;
    cursor: pointer;
}