.spinner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba($color: #000000, $alpha: 0.6);
    text-align: center;
    width: 100%;
    height: 100vh;
    margin: auto;
    &__svg {
        position: absolute;
        top: calc(50% - 5rem);
        left: calc(50% - 5rem);
        padding: 0.4rem;
        background-color: white;
        border-radius: 0.4rem;
        box-shadow: 0 0 10px #00000088;
        width: 10rem;
        height: 10rem;
    }
}